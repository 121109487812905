<template>
  <div>
    <v-card class="mx-auto" color="white" width="100%"
      style="border-radius: 20px; box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1)!important;" elevation="0">
      <!-- Versión Desktop -->
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-card-title @click="showAll" v-if="!showEmails" style="justify-content: center; cursor: pointer;">
          <v-img :src="emailImg" max-width="43" alt=""> </v-img>
          <br />
        </v-card-title>
        <v-card-title v-if="showEmails" style="cursor: pointer;">
          <v-row>
            <v-col cols="10" @click="showAll">
              <v-row>
                <v-col cols="2">
                  <v-img :src="emailImg" max-width="25" alt=""> </v-img>
                </v-col>
                <v-col cols="10">
                  <span>
                    Emails
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2" @click="OpenDialogCreateEmail = true" style="text-align: end;"
              v-if="rol != 'PROSPECTO' && canEdit">
              <v-img :src="AddIcon" alt="" style="width: 50px;"> </v-img>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text style="text-align: center;" v-if="!showEmails">
          <span style="
              font-size: 1.25rem;
              font-weight: 500;
              letter-spacing: 0.0125em;
              line-height: 2rem;
              word-break: break-all;
              color: rgba(0, 0, 0, 0.87);
          ">
            Emails
          </span>
        </v-card-text>
        <v-card-text v-if="showEmails">
          <div v-if="emails.length > 0" style="height: 180px !important;">
            <v-row v-for="(e, idx) in emails.slice(0, 3)" :key="idx">
              <v-col cols="12" class="custom-list">
                {{ e.subject }}
                <span style="color:#0070C0; cursor: pointer; " @click="OpenDialogShowEmails = true">
                  Ver
                </span>
              </v-col>
            </v-row>
          </div>
          <div v-if="emails.length > 0">
            <v-row style="text-align: center;">
              <v-col cols="12">
                <v-btn elevation="0" color="white" @click="OpenDialogShowEmails = true">
                  <span style="color:#0070C0">
                    ver mas
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else style="height:  216px !important; text-align: center;">
            <v-row style="text-align: center;">
              <v-col cols="12">
                <h3>
                  No hay nada por el momento
                </h3>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </template>
      <!-- Versión Mobile -->
      <template v-else>
        <v-card-title @click="showAll" v-if="!showEmails" style="justify-content: center; cursor: pointer;">
          <v-img :src="emailImg" max-width="43" alt=""></v-img>
          <br />
        </v-card-title>
        <v-card-title v-if="showEmails" style="cursor: pointer; text-align: center; gap: 20px;">
          <v-img :src="emailImg" max-width="30" alt="" />
          <span style="display: block; font-size: 1.2rem;">Emails</span>
        </v-card-title>

        <v-card-text style="text-align: center;" v-if="!showEmails">
          <span style="
              font-size: 1rem;
              font-weight: 500;
              letter-spacing: 0.0125em;
              line-height: 1.5rem;
              word-break: break-all;
              color: rgba(0, 0, 0, 0.87);
            ">
            Emails
          </span>
        </v-card-text>

        <v-card-text v-if="showEmails">
          <div v-if="emails.length > 0">
            <v-row v-for="(e, idx) in emails.slice(0, 3)" :key="idx">
              <v-col cols="12" class="custom-list">
                {{ e.subject }}
                <span style="color:#0070C0; cursor: pointer; " @click="OpenDialogShowEmails = true">
                  Ver
                </span>
              </v-col>
            </v-row>
          </div>
          <div v-if="emails.length > 0">
            <v-row>
              <v-col cols="12" style="text-align: center;">
                <v-btn elevation="0" color="white" @click="OpenDialogShowEmails = true">
                  <span style="color:#0070C0">
                    ver más
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else style="height: 216px; text-align: center;">
            <v-row>
              <v-col cols="12">
                <h3>No hay nada por el momento</h3>
              </v-col>
            </v-row>
            <v-row style="margin-top: 16px;">
              <v-col cols="12" style="text-align: center;">
                <v-btn outlined color="#00a7e4" @click="OpenDialogCreateEmail = true" rounded>
                  Agregar Email
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </template>
    </v-card>

    <create-email-dialog :OpenDialogCreateEmail="OpenDialogCreateEmail" :emailRoutesConfig="emailRoutesConfig"
      @closeModal="OpenDialogCreateEmail = false" @success="getData">
    </create-email-dialog>
    

    <ShowEmailsDialog :emails="emails" :OpenDialogShowEmails="OpenDialogShowEmails"
      @closeModal="OpenDialogShowEmails = false" @openCreateModal="OpenDialogCreateDocument = true">
    </ShowEmailsDialog>
  </div>
</template>

<script>
import CreateEmailDialog from "@/components/commonComponents/dialogs/CreateEmailDialog.vue";
import ShowEmailsDialog from "../dialogs/ShowEmailsDialog.vue";
import email from "@/assets/email.svg";
import addIcon from "@/assets/addIcon.svg";

export default {
  components: {
    CreateEmailDialog,
    ShowEmailsDialog,
  },

  data() {
    return {
      showEmails: false,
      OpenDialogCreateEmail: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      OpenDialogCreateDocument: false,
      OpenDialogShowEmails: false,
      emailImg: email,
      AddIcon: addIcon,
    };
  },
  props: {
    emails: Array,
    emailRoutesConfig: Object,
    openAll: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    openAll(val) {
      this.showEmails = val;
    },
  },

  methods: {
    getData() {
      this.$emit("getData");
    },
    showAll() {
      this.$emit("showAll", !this.showEmails);
    },
  },
};
</script>

<style>
.rounded-card {
  border-radius: 20px;
  max-width: "100%";
}

.custom-list {
  border-radius: 5px;
  text-align: center;
  margin: 5px 0px 5px 0px;
  background-color: aliceblue;
}
</style>
